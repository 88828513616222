module.exports.CONTACT_DATA = {
    sectionOne: {
        title: "Let's Talk",
        descriptionOne: "Have questions?",
        descriptionTwo: "Fill out our form, and we'll get in touch with you soon!"

    },
    sectionTwo: {
        title: "Email",
        descriptionOne: "info@rayv.ai"
    },
    sectionThree: {
        title: "Socials",
        links: [
            { label: "Instagram", url: 'https://www.instagram.com/rayvapp?igsh=emkzYm56NnNyY29u', target: "sky_insta" },
            { label: "Linkedin", url: 'https://www.linkedin.com/company/rayv-ai', target: "sky_linkedin" }
        ]
    },
    inputForm: [
        {
            "type": "text",
            "name": "name",
            "label": "Name",
            "styles": { placeholder: 'Joe' },
            "options": ""
        },
        {
            "type": "email",
            "name": "email",
            "label": "Email",
            "styles": { placeholder: 'example@gmail.com' },
            "options": ""
        },
        {
            "type": "text",
            "name": "contact",
            "label": "Phone",
            "styles": { placeholder: '(123)-456-7890' },
            "options": ""
        },
        {
            "type": "text",
            "name": "subject",
            "label": "What can we help you with ?",
            "styles": { placeholder: 'Mention the area that you need help with' },
            "options": ""
        },
        {
            "type": "textarea",
            "name": "message",
            "label": "Message",
            "styles": { placeholder: 'Write your message' },
            "options": ""
        },

    ],
    formProperties: {
        "name": '',
        "email": '',
        "contact": '',
        "subject": '',
        "message": ''
    }
}