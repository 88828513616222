import React from 'react';
import { Row, Col, Card } from 'reactstrap';

import { DATA } from './Data';

import './Benfits.scss';

export default class Benfits extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            active: 'BUSINESS'
        }
    }

    handleButton(activeBtn) {
        this.setState({ active: activeBtn })
    }

    render() {
        const { active } = this.state;
        return (
            <section className='section bg benfits-container pt-5'>
                <h1 className='section-title text-center py-3'>{DATA.title}</h1>
                <div className='text-center'>
                    {Object.keys(DATA.cards).map((button, buttonIdx) =>
                        <React.Fragment key={buttonIdx}>
                            <button
                                className={`${active !== button ? 'action-button-opacity' : ''} action-button mx-2`}
                                onClick={this.handleButton.bind(this, button)}
                            >
                                {DATA.cards[button]?.title}
                            </button>
                        </React.Fragment>
                    )}
                </div>
                <div className='py-5'>
                    <Card className='benfits-card p-3'>
                        <Row xs="2">
                            <Col className='content-col'>
                                <div>
                                    <h1 className='mb-3 benfits-card-title mb-0'>{DATA.cards[active]?.title}</h1>
                                    <ul>
                                        {DATA.cards[active]?.points.map((point, pointIdx) => <li key={pointIdx} className="benfits-card-points">{point}</li>)}
                                    </ul>
                                </div>
                            </Col>
                            <Col className='benfits-image-container'>
                                <div>
                                    <img src={DATA.cards[active].image} alt='benfits' className='benfits-image' />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </section>
        )
    }
}