import React, { Component } from 'react'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";

import platformLogo from '../../assets/images/svg/logo.svg';
import { MENU_ITEMS } from './Data';
import './Header.scss';

const ENV_CONFIG = window._env_ || {};

export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuItems: MENU_ITEMS,
            isOpen: false
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    handleSignIn() {
        const linkElement = document.createElement('a');
        linkElement.href = `${ENV_CONFIG.HOST_PORTAL}/admin`;
        linkElement.target = `_PORTAL`;
        linkElement.click();
    }

    render() {
        const { menuItems, isOpen } = this.state;
        return <section>
            <header className='header-wrapper'>
                <div className="nav-bar-container font-weight-bold fw-bold text-white">
                    <div>
                        <Navbar expand="lg" className="justify-content-between w-100 ps-0">
                            <NavbarBrand href="/" className="logo-container ps-0 me-0">
                                <img src={platformLogo} alt="Rayv" className="logo" />
                            </NavbarBrand>
                            <div className='menu-area d-flex'>
                                <NavbarToggler onClick={this.toggle.bind(this)} />
                                <Collapse isOpen={isOpen} navbar>
                                    <Nav className="menu-container" navbar>
                                        {menuItems.map((item, itemIdx) =>
                                            <NavItem key={itemIdx} className="mx-3">
                                                <NavLink href={item.to} className="nav-label">
                                                    {item.label}
                                                </NavLink>
                                            </NavItem>)}
                                    </Nav>
                                </Collapse>
                            </div>
                            <span className='d-flex'>
                                <button className='action-button' onClick={this.handleSignIn.bind(this)}>Sign In</button>
                            </span>
                        </Navbar>
                    </div>
                </div>
            </header>
        </section>
    }
}

export default Header

