import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Autoplay, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';

import { CARDS, SETTINGS } from './Data';

import './HowItWorks.scss';

export default class HowItWorks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            settings: SETTINGS,
        }
    }

    render() {
        return <section className='section bg hiw-container'>
            <h1 className='section-title text-center py-3'>How It Works</h1>

            <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards, Autoplay, Pagination]}
                className="mySwiper"
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
            >
                {CARDS.map((card, idx) => <SwiperSlide key={idx} className={''}>
                    <Card key={idx} className={`slide-card p-2 m-2`} style={{ backgroundColor: card.bgColor, color: card.textColor }} >
                        <Row>
                            <Col className='card-content-col'>
                                <div className='text-center'>
                                    <h1 className='card-title'>{card.title}</h1>
                                    <p className='card-description'>{card.description}</p>
                                </div>
                            </Col>
                            <Col className='card-image-col'>
                                <div className='card-image-container'>
                                    <img src={card.image} className='card-image' alt='' />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </SwiperSlide>)}
            </Swiper>

        </section>
    }
}