import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { PRIVACY_DATA } from './PrivacyAndTermsData';
import './privacy.scss';

export class Privacy extends Component {
    render() {
        return <div className="privacy mt-3">
            <Row xs="1" md="1">
                <Col>
                    <h3>Privacy Policy</h3>
                </Col>
            </Row>
            <Row xs="1" md="1">
                {PRIVACY_DATA.map((privacy, privacyIdx) =>
                    <div key={privacyIdx}>
                        {privacy.mainPara &&
                            <Col>
                                <h5>{privacy.category}</h5>
                                <em>{privacy.lastUpdate}</em>
                                <p className="mt-2 mb-0">{privacy.description} <Link to="/terms"><u>{privacy.terms}</u></Link></p>
                                <p className="mt-2 mb-0">{privacy.description_p1}</p>
                                <p className="mt-2 mb-0">{privacy.description_p2}</p>
                            </Col>
                        }
                        {!privacy.mainPara && <Col>
                            <h6 className="my-3 font-weight-bold">{privacyIdx}. {privacy.category}</h6>
                            <p>{privacy.description} <a href={`mailto:${privacy.mailTo}`}><u>{privacy.mailTo}</u></a> </p>

                            {privacy.children.map((child, childIdx) =>
                                <ul key={childIdx}>
                                    <li className="font-weight-bold mr-3 or-list">{child.category}</li>
                                    <span>{child.description}</span>
                                </ul>
                            )}
                        </Col>
                        }
                    </div>
                )}
            </Row>
            <h5>Thank you !</h5>
        </div>
    }
}

export default Privacy
