module.exports.CARDS = [
    {
        title: `View`,
        description: `Explore the latest campaigns launched by local businesses on our app and discover the enticing rewards they're offering.`,
        image: require('../../assets/images/view.png'),
        bgColor: '#FF4444',
        textColor: '#FFFFFF'
    },
    {
        title: `Create`,
        description: `Craft compelling video marketing content to spotlight the exceptional products of local businesses. Amplify the buzz and share their stories far and wide.`,
        image: require('../../assets/images/create.png'),
        bgColor: '#FFBB33',
        textColor: ''
    },
    {
        title: `Win`,
        description: `Earn our exclusive tokens, known as "Rayvens," when your video is chosen by a business. Your creativity is not only celebrated but also rewarded!`,
        image: require('../../assets/images/win.png'),
        bgColor: '#00C851',
        textColor: '#FFFFFF'
    },
    {
        title: `Convert`,
        description: `Transform your local marketing support into cash by converting the Rayvens you've earned through video creation! Monetize your advocacy and see your efforts turn into tangible rewards.`,
        image: require('../../assets/images/convert.png'),
        bgColor: '#33B5E5',
        textColor: '#FFFFFF'
    },
];

module.exports.SETTINGS = {
    className: "slide-container",
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};