module.exports.FAQS = {
    title: 'FAQ',
    list: [
        {
            question: `What problems does Rayv solve for my business?`,
            answer: `Rayv saves SMBs money by enabling crowdsourced video content from local consumers rather than expensive ad agencies or influencers. This authentic, locally generated content also saves time otherwise spent on content creation. User-generated content improves engagement and sales because recommendations from real customers have higher trust and conversion rates than generic marketing`
        },
        {
            question: `How does the content moderation and approval process work?`,
            answer: `Our commitment to a safe platform for all involves a dual approach to content moderation. Each piece of content undergoes both automated checks and thorough human review before publication. We uphold a strict policy of not publishing any content without this careful evaluation`
        },
        {
            question: `What type of content can users generate on Rayv?`,
            answer: `Recognizing the unparalleled effectiveness of video in marketing, our platform empowers users to create compelling short-form videos with a maximum duration of 15 seconds. Your creativity sets the boundaries, with endless possibilities to captivate your audience`
        },
        {
            question: `When and how do I get rewarded for my contributions?`,
            answer: `Earn rewarding recognition when a brand selects your video as a winner in their campaign. The best part? There's no limit to the number of campaigns you can participate in, giving you endless opportunities to shine and reap the rewards`
        },
        {
            question: `How can I unify my multi-channel marketing on Rayv's platform?`,
            answer: `With our unified marketing dashboard, effortlessly handle content scheduling, publishing, and performance analysis across all your social media platforms from a single, streamlined location`
        }
    ]
}
