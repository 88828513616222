import React, { Component } from 'react';
import { Row, Col } from "reactstrap";

import platformLogo from '../../assets/images/svg/logo.svg';
import { FOOTER_DATA } from './Data';
import './Footer.scss';

export class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: FOOTER_DATA

        }
    }

    render() {
        const { data } = this.state;

        return <section className='footer-container'>
            <div className="footer-top">
                <div className="mx-4">
                    <Row>
                        <Col md="5" xs="12" className='logo-col'>
                            <div className="logo-container">
                                <img src={platformLogo} alt="Rayv" className="logo" />
                            </div>
                        </Col>
                        {data.links.map((category, i) =>
                            <Col md="" xs="6" key={i} className='pt-2 px-0'>
                                <h4 className="text-white font-weight-bold fw-bold">
                                    {category.name}
                                </h4>
                                <ul className="pl-0">
                                    {category.list.map((item, idx) =>
                                        <li key={idx}>
                                            <a href={item.to}>{item.label}</a>
                                        </li>
                                    )}
                                </ul>
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
        </section>
    }
}

export default Footer