const { CONTACT_MAIL, PORTAL_CONTACT } = require('../../services/ServiceConstants');

module.exports.PRIVACY_DATA = [
    {
        id: "privacyPara",
        mainPara: true,
        category: "Rayv Inc.",
        lastUpdate: "Last Updated Feb 15, 2024",
        description: "This Privacy Policy explains what Personal Data (defined below) we collect, how we use and share that data, and your choices concerning our data practices. This Privacy Policy is incorporated into and forms part of our",
        description_p1: "Hi there! Welcome to the website of Rayv.Inc. (“bizportal” app), (“Company,” “we,” “us,” or “our”). Through our bizportal application (the “Portal”), we allow users to engage in chat, video, audio conversations and hyperlocal services with friends and other people around the world and the bizportal, is referred to in this Privacy Policy as the “App, Service”.",
        description_p2: "Before using the App/Service or submitting any Personal Data to Company, please review this Privacy Policy carefully and contact us if you have any questions. By using the App/Service, you agree to the practices described in this Privacy Policy. If you do not agree to this Privacy Policy, please do not access the App or otherwise use the Service.",
        terms: "Terms of Service.",
    },
    {
        id: "privacy1",
        mainPara: false,
        category: "PERSONAL DATA WE COLLECT",
        description: "We collect information that alone or in combination with other information in our possession could be used to identify you (“Personal Data”) as follows:",
        children: [
            {
                category: "Personal Data You Provide:",
                description: "We collect the following categories of Personal Data from you when you use our Service, including when you sign up for an account, create or share content, and message or communicate with others.",
            },
            {
                category: "Information you provide:",
                description: "We collect content, communications, and other information you provide, including when you sign up for an account, create or share content, and message or communicate with others. To create and manage an account, you may provide us with personal data, including your name, phone number, a photo of yourself, an email address, and a username. We use your contact information to authenticate your account and keep it secure and to communicate with you about the App/Service",
            },
            {
                category: "Files:",
                description: "Files shared on the App using the data capsule feature, are not stored by the App. All temporary data sharing is encrypted and shared as data link by the person sharing the content. We delete the temporary data link as per the user’s expiry settings on the App Capsule data preferences set during sharing the content.",
            },
            {
                category: "Audio:",
                description: "Audio from speakers is captured, and all temporary audio recordings are encrypted. Audio from muted speakers is never captured. Solely for the purpose of supporting incident investigations, we temporarily record the audio. We retain the audio for the purposes of investigating the incident, and then delete it when the investigation is complete. We delete the temporary audio recording when the call ends, if no incident is reported.",
            },
            {
                category: "Video:",
                description: "Video from cameras is captured, and all temporary video recordings are encrypted. Solely for the purpose of supporting incident investigations, we temporarily record the video. We retain the video for the purposes of investigating the incident, and then delete it when the investigation is complete. We delete the temporary video recording when the video call ends, if no incident is reported.",
            },
            {
                category: "Networks and connections:",
                description: "We collect information about the people, accounts, and groups you are connected to and how you interact with them through our App/Service. We use your contact information and (if you choose to provide us with access to it), your address book information to recommend your account publicly shared content with others.",
            },
            {
                category: "Usage:",
                description: "We may choose to collect information about how you use our App/Service, such as the types of conversations you engage in, content you share, features you use, actions you take, people or accounts you interact with, and the time, frequency, and duration of your use.",
            },
            {
                category: "Communication Data:",
                description: "We may choose to collect information when you contact us with questions or concerns and when you voluntarily respond to requests for your opinion and feedback.",
            },
            {
                category: "Activity Data:",
                description: "When you visit, use, and interact with the App / Service, we may receive certain information about your visit, use, or interactions. For example, we may monitor the number of people that visit the App, peak hours of visits, which screens are visited, device used for service, broad geographical information, and navigation patterns. In particular, the following information is created and automatically logged in our systems.",
            },
            {
                category: "Log Data:",
                description: "Log Data includes your Internet Protocol address, App settings, operating system, the date and time of your request, how you interacted with the App, and how you interacted with mobile notifications.",
            },
            {
                category: "Device Data:",
                description: "Includes type and name of the device, operating system, and App version you are using. Information collected may depend on the type of device you use and its settings.",
            },
            {
                category: "Usage Data:",
                description: "We collect information about how you use our Service, such as the types of content that you view or engage with, the features you use, the actions you take, and the time, frequency, and duration of your activities.",
            },
            {
                category: "Location Data:",
                description: "We derive a rough estimate of your location from your IP address.",
            },
            {
                category: "Derived Data",
                description: "We may infer your preferences for content and features of the App, or future products and services, based on the Personal Data we collect about you.",
            },
            {
                category: "Analytics Data:",
                description: "We use Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics helps us analyse how users use the App and enhance your experience when you use the Service. For more information on how Google uses this data, go to www.google.com/policies/privacy/partners/.",
            },
        ],
    },
    {
        id: "privacy2",
        mainPara: false,
        category: "HOW WE USE PERSONAL DATA",
        description: "We may use Personal Data for the following purposes:",
        children: [
            {
                category: "",
                description: "To provide the App / Service",
            },
            {
                category: "",
                description: "To respond to your inquiries, comments, feedback, or questions",
            },
            {
                category: "",
                description: "To send administrative information to you, for example, information regarding the Service and changes to our terms, conditions, and policies",
            },
            {
                category: "",
                description: "To analyse how you interact with our Service",
            },
            {
                category: "",
                description: "To maintain and improve the Service",
            },
            {
                category: "",
                description: "To develop new products and services",
            },
            {
                category: "",
                description: "To authenticate your account and keep it secure",
            },
            {
                category: "",
                description: "To enforce our Terms of Service",
            },
            {
                category: "",
                description: "To create your account, facilitate network connections, recommend content, and further personalize the Service for you",
            },
            {
                category: "",
                description: "To prevent spam, fraud, abuse, criminal activity, or misuses of our Service, and to ensure the security of our IT systems, architecture, and networks and",
            },
            {
                category: "",
                description: "To comply with legal obligations and legal process and to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other third parties.",
            },
            {
                category: "Aggregated Information",
                description: "We may aggregate Personal Data and use the aggregated information to analyse the effectiveness of our App / Service, to improve and add features to our Service, and for other similar purposes. In addition, from time to time, we may analyse the general behaviour and characteristics of users of our App / Service and share aggregated information like general user statistics with prospective business partners and parent company Rayv Inc. We may collect aggregated information through the App / Service, and through other means described in this Privacy Policy.",
            },
        ],
    },
    {
        id: "privacy3",
        mainPara: false,
        category: "SHARING AND DISCLOSURE OF PERSONAL DATA",
        description: "We collect information that alone or in combination with other information in our possession could be used to identify you (“Personal Data”) as follows:",
        children: [
            {
                category: "Personal Data You Provide:",
                description: "We collect the following categories of Personal Data from you when you use our Service, including when you sign up for an account, create or share content, and message or communicate with others.",
            },
            {
                category: "Information you provide:",
                description: "We collect content, communications, and other information you provide, including when you sign up for an account, create or share content, and message or communicate with others. To create and manage an account, you may provide us with personal data, including your name, phone number, a photo of yourself, an email address, and a username. We use your contact information to authenticate your account and keep it secure and to communicate with you about the App/Service",
            },
        ]
    },
    {
        id: "privacy4",
        mainPara: false,
        category: "DATA RETENTION",
        description: "We keep Personal Data for as long as reasonably necessary for the purposes described in this Privacy Policy, while we have a business need to do so, or as required by law (e.g. for tax, legal, accounting, or other purposes), whichever is longer.",
        children: []
    },
    {
        id: "privacy5",
        mainPara: false,
        category: "UPDATE YOUR INFORMATION",
        description: "Please log in to your account on the App if you need to change or correct your Personal Data, or if you wish to delete your account.",
        children: []
    },
    {
        id: "privacy6",
        mainPara: false,
        category: "CHILDREN",
        description: "Our Service is not directed to individuals who are under the age of 18. Company does not knowingly collect Personal Data from individuals under the age of 18. If you have reason to believe that an individual under the age of 18 has provided Personal Data to Company through the Service please contact us and we will endeavour to delete that information from our databases.",
        children: []
    },
    {
        id: "privacy7",
        mainPara: false,
        category: "SECURITY",
        description: "You use the App / Service at your own risk. We implement commercially reasonable technical, administrative, and organizational measures to protect Personal Data both online and offline from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet data transmission is ever fully secure or error free. In particular, e-mail sent to or from us may not be secure. Therefore, you should take special care in deciding what information you send to us via the Service or e-mail. Please keep this in mind when disclosing any Personal Data to Company via the Internet. In addition, we are not responsible for circumvention of any privacy settings or security measures contained on the Service, or third-party websites. Finally, we cannot control the actions of users on the platform, who may seek to use third-party apps or devices to record, store, or share content or communication without other users' prior consent. Please keep this in mind when using the Service.",
        children: []
    },
    {
        id: "privacy8",
        mainPara: false,
        category: "INTERNATIONAL USERS",
        description: "By using our Service, you understand and acknowledge that your Personal Data will be transferred from your location to our facilities and servers in the United States, and where applicable, to the servers of the technology partners we use to provide our Service.",
        children: []
    },
    {
        id: "privacy9",
        mainPara: false,
        category: "CHANGES TO THE PRIVACY POLICY",
        description: "The Service and our business may change from time to time. As a result, we may change this Privacy Policy at any time. When we do, we will post an updated version on this page, unless another type of notice is required by the applicable law. By continuing to use our Service or providing us with Personal Data after we have posted an updated Privacy Policy, or notified you by other means if applicable, you consent to the revised Privacy Policy and practices described in it.",
        children: []
    },
    {
        id: "privacy10",
        mainPara: false,
        category: "CONTACT US",
        description: "If you have any questions about our Privacy Policy or information practices, please feel free to contact us anytime via e-mail at ",
        mailTo: CONTACT_MAIL,
        children: []
    },
]

module.exports.TERMS_DATA = [
    {
        id: "termsPara",
        mainPara: true,
        category: "Rayv Inc.",
        lastUpdate: "Last Updated Feb 15, 2024",
        info: "Please read these terms and conditions carefully before using Our Service.",
        privacy: "Privacy Policy.",
        child: false,
    },
    {
        id: "terms1",
        mainPara: false,
        category: "Interpretation and Definitions",
        description_p1: undefined,
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: true,
        children: [
            {
                title: "Interpretation",
                description_p1: "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.",
                description_p1_child: false,
                description_p2: undefined,
                description_p2_child: false,
                description_p3: undefined,
                description_p3_child: false,
                description_p4: undefined,
                description_p4_child: false,
                subChild: false,
            },
            {
                title: "Definitions",
                description_p1: "For the purposes of these Terms and Conditions:",
                description_p1_child: true,
                description_p1_children: [
                    {
                        subTitle: "Application",
                        description: "means the software program provided by the Company downloaded by You on any electronic device, named Rayv",
                    },
                    {
                        subTitle: "Application Store",
                        description: "means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.",
                    },
                    {
                        subTitle: "Affiliate",
                        description: "means an entity that controls, is controlled by or is under common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.",
                    },
                    {
                        subTitle: "Account",
                        description: "means a unique account created for You to access our Service or parts of our Service.",
                    },
                    {
                        subTitle: "Country",
                        description: "refers to: Texas, United States",
                    },
                    {
                        subTitle: "Company",
                        description: "(referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement) refers to Rayv Inc, 1400 Lavaca Street, Austin, TX 78701.",
                    },
                    {
                        subTitle: "Content",
                        description: "refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.",
                    },
                    {
                        subTitle: "Device",
                        description: "means any device that can access the Service such as a computer, a cell phone or a digital tablet.",
                    },
                    {
                        subTitle: "Feedback",
                        description: "means feedback, innovations or suggestions sent by you regarding the attributes, performance or features of our Service.",
                    },
                    {
                        subTitle: "Service",
                        description: "refers to the Application.",
                    },
                    {
                        subTitle: "Terms and Conditions",
                        description: "(also referred as 'Terms') mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.",
                    },
                    {
                        subTitle: "Third-party Social Media Service",
                        description: "means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.",
                    },
                    {
                        subTitle: "You",
                        description: "means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
                    },
                ],
                description_p2: undefined,
                description_p2_child: false,
                description_p3: undefined,
                description_p3_child: false,
                description_p4: undefined,
                description_p4_child: false,
            },
        ],
    },
    {
        id: "terms2",
        mainPara: false,
        category: "Acknowledgment",
        description_p1: "These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.",
        description_p2: "Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.",
        description_p3: "By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.",
        description_p4: "You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.",
        description_p5: "Your access to and use of the Service is also conditioned on your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of your personal information when You use the Application or the Website and tells You about your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.",
        child: false,
    },
    {
        id: "terms3",
        mainPara: false,
        category: "User Accounts",
        description_p1: "When you create an account with Us, you must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on Our Service.",
        description_p2: "You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under your password, whether your password is with Our Service or a Third-Party Social Media Service.",
        description_p3: "You agree not to disclose your password to any third-party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of your account.",
        description_p4: "You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.",
        child: false,
    },
    {
        id: "terms4",
        mainPara: false,
        category: "Content",
        description_p1: undefined,
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: true,
        children: [
            {
                title: "Your Right to Post Content",
                description_p1: "Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.",
                description_p1_child: false,
                description_p2: "By posting Content to the Service, you grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.",
                description_p2_child: false,
                description_p3: "You represent and warrant that: (i) the Content is yours (you own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.",
                description_p3_child: false,
                description_p4: undefined,
                description_p4_child: false,
            },
            {
                title: "Content Restrictions",
                description_p1: "The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using your account.",
                description_p1_child: false,
                description_p2: "You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libellous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:",
                description_p2_child: true,
                description_p2_children: [
                    {
                        subTitle: "",
                        description: "Unlawful or promoting unlawful activity.",
                    },
                    {
                        subTitle: "",
                        description: "Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.",
                    },
                    {
                        subTitle: "",
                        description: "Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.",
                    },
                    {
                        subTitle: "",
                        description: "Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.",
                    },
                    {
                        subTitle: "",
                        description: "Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.",
                    },
                    {
                        subTitle: "",
                        description: "Impersonating any person or entity including the Company and its employees or representatives.",
                    },
                    {
                        subTitle: "",
                        description: "Violating the privacy of any third person.",
                    },
                    {
                        subTitle: "",
                        description: "False information and features.",
                    },
                ],
                description_p3: "The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content.",
                description_p3_child: false,
                description_p4: "The Company further reserves the right to make formatting and edits and change the manner any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content. As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.",
                description_p4_child: false,
            },
            {
                title: "Content Backups",
                description_p1: "Although regular backups of Content are performed, the Company do not guarantee there will be no loss or corruption of data.",
                description_p1_child: false,
                description_p2: "Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.",
                description_p2_child: false,
                description_p3: "The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.",
                description_p3_child: false,
                description_p4: "You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.",
                description_p4_child: false,
            },
        ],
    },
    {
        id: "terms5",
        mainPara: false,
        category: "Copyright Policy",
        description_p1: undefined,
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: true,
        children: [
            {
                title: "Intellectual Property Infringement",
                description_p1: "We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.",
                description_p1_child: false,
                description_p2: "If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit your notice in writing to the attention of our copyright agent via email at [email protected] and include in your notice a detailed description of the alleged infringement.",
                description_p2_child: false,
                description_p3: "You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing your copyright.",
                description_p3_child: false,
                description_p4: undefined,
                description_p4_child: false,
            },
            {
                title: "DMCA Notice and DMCA Procedure for Copyright Infringement Claims",
                description_p1: "You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):",
                description_p1_child: true,
                description_p1_children: [
                    {
                        subTitle: "",
                        description: "An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.",
                    },
                    {
                        subTitle: "",
                        description: "A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.",
                    },
                    {
                        subTitle: "",
                        description: "Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.",
                    },
                    {
                        subTitle: "",
                        description: "Your address, telephone number, and email address.",
                    },
                    {
                        subTitle: "",
                        description: "A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.",
                    },
                    {
                        subTitle: "",
                        description: "A statement by You, made under penalty of perjury, that the above information in your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.",
                    },
                ],
                description_p2: "You can contact our copyright agent via email at [email protected] Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service.",
                description_p2_child: false,
                description_p3: undefined,
                description_p3_child: false,
                description_p4: undefined,
                description_p4_child: false,
            },
        ],
    },
    {
        id: "terms6",
        mainPara: false,
        category: "Intellectual Property",
        description_p1: "The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.",
        description_p2: "The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.",
        description_p3: "Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.",
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms7",
        mainPara: false,
        category: "Your Feedback to Us",
        description_p1: "You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.",
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms8",
        mainPara: false,
        category: "Links to Other Websites",
        description_p1: "Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.",
        description_p2: "The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.",
        description_p3: "We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.",
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms9",
        mainPara: false,
        category: "Termination",
        description_p1: "We may terminate or suspend your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.",
        description_p2: "Upon termination, your right to use the Service will cease immediately. If You wish to terminate your Account, You may simply discontinue using the Service.",
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms10",
        mainPara: false,
        category: "Limitation of Liability",
        description_p1: "Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.",
        description_p2: "To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.",
        description_p3: "Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.",
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms11",
        mainPara: false,
        category: "'AS IS' and 'AS AVAILABLE' Disclaimer",
        description_p1: "The Service is provided to You 'AS IS' and 'AS AVAILABLE' and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.",
        description_p2: "Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.",
        description_p3: "Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.",
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms12",
        mainPara: false,
        category: "Governing Law",
        description_p1: "The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and your use of the Service. your use of the Application may also be subject to other local, state, national, or international laws.",
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms13",
        mainPara: false,
        category: "Disputes Resolution",
        description_p1: "If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.",
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms14",
        mainPara: false,
        category: "For European Union (EU) Users",
        description_p1: "If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.",
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms15",
        mainPara: false,
        category: "United States Legal Compliance",
        description_p1: "You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a 'terrorist supporting' country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.",
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms16",
        mainPara: false,
        category: "Severability and Waiver",
        description_p1: undefined,
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: true,
        children: [
            {
                title: "Severability",
                description_p1: "If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.",
                description_p1_child: false,
                description_p2: undefined,
                description_p2_child: false,
                description_p3: undefined,
                description_p3_child: false,
                description_p4: undefined,
                description_p4_child: false,
            },
            {
                title: "Waiver",
                description_p1: "Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.",
                description_p1_child: false,
                description_p2: undefined,
                description_p2_child: false,
                description_p3: undefined,
                description_p3_child: false,
                description_p4: undefined,
                description_p4_child: false,
            },
        ],
    },
    {
        id: "terms17",
        mainPara: false,
        category: "Translation Interpretation",
        description_p1: "These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.",
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms18",
        mainPara: false,
        category: "Changes to These Terms and Conditions",
        description_p1: "We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.",
        description_p2: "By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.",
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: false,
    },
    {
        id: "terms19",
        mainPara: false,
        category: "Contact Us",
        description_p1: "If you have any questions about these Terms and Conditions, You can contact us:",
        description_p2: undefined,
        description_p3: undefined,
        description_p4: undefined,
        description_p5: undefined,
        child: true,
        children: [
            {
                title: undefined,
                description_p1: undefined,
                description_p1_child: true,
                description_p1_children: [
                    {
                        subTitle: "",
                        description: "By email:",
                        navigateTo: `mailto:${CONTACT_MAIL}`,
                        navigateLabel: CONTACT_MAIL
                    },
                    {
                        subTitle: "",
                        description: "By visiting this page on our website: ",
                        navigateTo: PORTAL_CONTACT,
                        navigateLabel: "Rayv.ai"
                    },
                ],
                description_p2: undefined,
                description_p2_child: false,
                description_p3: undefined,
                description_p3_child: false,
                description_p4: undefined,
                description_p4_child: false,
            },
        ],
    },
]
