import React from 'react';
import { Row, Col, Card } from 'reactstrap';

import { DATA } from './Data';

import './Marketing.scss';

export default class Marketing extends React.Component {
    render() {
        return (
            <section className='section bg marketing-container'>
                <h1 className='section-title text-center pb-3'>{DATA.title}</h1>
                <h1 className='primary-color-title text-center pb-3'>{DATA.colorTitle}</h1>

                <Row xs="1" md="2" className='pt-4'>
                    {DATA.cards.map((card, cardIdx) =>
                        <Col key={cardIdx} className='p-2'>
                            <Card className='marketing-card text-center p-3'>
                                <Row xs="1">
                                    <Col className='pb-4'>
                                        <div className='icon-container'><img src={card.icon.default} alt={card.title} /></div>
                                    </Col>
                                    <Col>
                                        <h1 className='mb-3'>{card.title}</h1>
                                    </Col>
                                    <Col>
                                        <p className=''>{card.description}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    )}
                </Row>
            </section>
        )
    }
}