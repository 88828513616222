import React, { Component } from 'react';

import Banner from '../banner/Banner';
import HowItWorks from '../howitworks/HowItWorks';
import Marketing from '../marketing/Marketing';
import Benfits from '../benfits/Benfits';
import Pricing from '../pricing/Pricing';
import Faqs from '../faqs/Faqs';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';
import './Home.scss';


export class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <section className='landing-page-container'>
            <section>
                <Banner />
            </section>
            <section>
                <HowItWorks />
            </section>
            <section>
                <Marketing />
            </section>
            <section>
                <Benfits />
            </section>
            <section>
                <Pricing />
            </section>
            <section>
                <Faqs />
            </section>
            <section>
                <Contact />
            </section>
            <section>
                <Footer />
            </section>
        </section >
    }
}

export default Home