import React from 'react';
import { Row, Col } from 'reactstrap';

import BannerImg from '../../assets/images/home-banner.png';

import './Banner.scss';

const ENV_CONFIG = window._env_ || {};

export default class Banner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggle: 'MOBILE_APP',
        }
    }

    handleToggle(value) {
        this.setState({ toggle: value })
    }

    getColValue(col) {
        const { toggle } = this.state;
        let value;
        if (window.innerWidth <= 1024) {
            value = 12;
        } else {
            if (col === 'FIRST_COL') {
                value = (toggle === 'MOBILE_APP') ? 7 : 5;
            } else {
                value = (toggle === 'MOBILE_APP') ? 5 : 7;
            }
        }

        return value;
    }

    handleAction(action) {
        const linkElement = document.createElement('a');
        linkElement.href = (action === 'STARTED') ? `${ENV_CONFIG.HOST_PORTAL}` : '/demo_calendar';
        linkElement.target = this.getTarget(action);
        linkElement.click();
    }

    getTarget(action) {
        let target = '_self';

        if (action === 'STARTED') {
            target = (window.innerWidth < 992) ? '_self' : '_PORTAL';
        }
        return target;
    }

    render() {
        const { toggle } = this.state;
        return (
            <div className='section bg banner-container'>
                <div>
                    <React.Fragment>
                        <Row className='banner-container-row'>
                            <Col xs={12} md={6}>
                                <div>
                                    <span className='fire-text p-2'>🔥 Crowdsourced marketing platform for SMBs</span>
                                    <h1 className='my-4 primary-title'>Customers Earn Rewards as Local Brand Advocates</h1>
                                    <p className='my-4 primary-description'>Transforming Customers Into a Brand's Most Effective Marketing Team</p>

                                    <button className={`action-button me-2`} onClick={this.handleAction.bind(this, "STARTED")}>Get Started</button>
                                    <button className={`action-button ms-2`} onClick={this.handleAction.bind(this, "DEMO")}>Book Demo</button>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className='banner-second-col'>
                                {/* <div>
                                    <div className='mb-3 text-center'>
                                        <button className={`${toggle !== 'MOBILE_APP' ? 'toggle-btn-opacity' : ''} toggle-btn`} onClick={this.handleToggle.bind(this, "MOBILE_APP")}>Mobile App</button>
                                        <button className={`${toggle !== 'BUSINESS_PORTAL' ? 'toggle-btn-opacity' : ''} toggle-btn`} onClick={this.handleToggle.bind(this, "BUSINESS_PORTAL")}>Business Portal</button>
                                    </div>
                                    <div className='banner-image-container'>
                                        <img src={toggle === 'MOBILE_APP' ? mobile : desktop} className={toggle === 'MOBILE_APP' ? 'mobile-banner-image' : 'desktop-banner-image'} alt='' />
                                    </div>
                                </div> */}
                                <div className='banner-image-container'>
                                    <img src={toggle === 'MOBILE_APP' ? BannerImg : BannerImg} className={toggle === 'MOBILE_APP' ? 'mobile-banner-image' : 'desktop-banner-image'} alt='' />
                                </div>

                            </Col>
                        </Row>

                    </React.Fragment>
                </div>
            </div>
        )
    }
}