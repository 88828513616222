module.exports.DATA = {
    title: `Forget Influencers, Social Media, SEO, and Traditional Marketing.`,
    colorTitle: `Embrace the Future with Crowdsourced Marketing`,
    cards: [
        {
            title: `Influencers`,
            description: `Diminishing authenticity as influencer marketing becomes oversaturated, leading to skepticism and reduced trust among consumers.`,
            icon: require('../../assets/images/svg/influencer.svg')
        },
        {
            title: `Social Media`,
            description: `Saturated social media platforms, making it difficult for businesses to break through the noise and capture audience attention effectively.`,
            icon: require('../../assets/images/svg/social-media.svg')
        },
        {
            title: `SEO`,
            description: `The costs associated with hiring SEO experts or agencies can be a significant burden for smaller businesses.`,
            icon: require('../../assets/images/svg/SEO.svg')
        },
        {
            title: `Traditional Marketing`,
            description: `The inflexibility of traditional marketing methods, hinders businesses from swiftly adapting to dynamic shifts in consumer preferences and market trends.`,
            icon: require('../../assets/images/svg/traditional.svg')
        }
    ]
}