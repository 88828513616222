import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import { FAQS } from './Data';
import './Faqs.scss';

export class Faqs extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <section id="faq" className='section bg faqs-container pt-5'>
            <h1 className='section-title text-center'>{FAQS.title}</h1>

            <div id='accordion'>
                {FAQS.list.map((faq, faqIdx) =>
                    <Row key={faqIdx} className="accordion accordion-row" id={`accordion${faqIdx}`} xs="1" >
                        <Col className="p-2 expand-button-container d-inline-block collapsed" data-bs-toggle="collapse" data-bs-target={`#collapse${faqIdx}`} aria-expanded="false" aria-controls={`#collapse${faqIdx}${faqIdx}`}>
                            <span className="expand-button float-right mx-2"></span>
                            <h5 className='faq-que d-inline'>{faq.question}</h5>
                        </Col>
                        <Col id={`collapse${faqIdx}`} className="px-3 collapse collapse-column" aria-labelledby={`accordion${faqIdx}`} data-bs-parent={`#accordion${faqIdx}`}>
                            <p className='faq-ans'>{faq.answer}</p>
                        </Col>
                    </Row>
                )}
            </div>
        </section>
    }
}

export default Faqs