import React from 'react';

import './ScrollTop.scss';

export default class ScrollTop extends React.Component {

    componentDidMount() {
        let mybutton = document.getElementById("back-to-top");
        let headerEle = document.getElementsByClassName("header-wrapper")[0];
        window.onscroll = function () {
            if (mybutton != null) {
                if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
                    mybutton.style.display = "block";
                    headerEle.style.backgroundColor = "#FFFFFF";
                } else {
                    mybutton.style.display = "none";
                    headerEle.style.backgroundColor = "#F0F0FF";
                }
            }
        };
    }

    scrollToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <div>
                <div onClick={this.scrollToTop.bind(this)} id="back-to-top" className="back-to-top fs-5"><i className="fa fa-arrow-up icons align-middle"></i></div>
            </div>
        )
    }
}
