import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { CONTACT_DATA } from './Data';
import './Contact.scss';

export class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: CONTACT_DATA,
            formProperties: JSON.parse(JSON.stringify(CONTACT_DATA?.formProperties || {}))
        }
    }

    handleChange(name, value) {
        let { formProperties } = this.state;
        formProperties[name] = value;
        this.setState({ formProperties: formProperties })
    }

    onSubmit() {
        let { name, email, contact, subject, message } = this.state.formProperties;
        const params = new URLSearchParams();

        let mailTo = `info@rayv.ai`;
        let body = `${message}
        
        Thanks
        ${name}
        ${email}
        ${contact}`

        params.set('cc', 'tanav@rayv.ai');
        params.set('from', email);
        params.set('subject', subject);
        params.set('body', body);

        const queryString = params.toString();
        const mailtoLink = `mailto:${mailTo}?${queryString}`;

        let anchorTag = document.createElement('a');
        anchorTag.href = mailtoLink;
        anchorTag.target = "_top";
        anchorTag.click()
    }

    render() {
        const { data, formProperties } = this.state;
        return <section>

            <section className='section contact-container pt-5' id='contact'>
                <Row xs="1" md="2">
                    <Col className='content-col'>
                        <div>
                            <div className='mb-4'>
                                <h1 className='section-title'>{data.sectionOne.title}</h1>
                                <p className='mb-0'>{data.sectionOne.descriptionOne}</p>
                                <p className='mb-0'>{data.sectionOne.descriptionTwo}</p>
                            </div>

                            <div className='mb-4'>
                                <h1 className='section-title'>{data.sectionTwo.title}</h1>
                                <a href={`mailto:${data.sectionTwo.descriptionOne}`} target='_self'>{data.sectionTwo.descriptionOne}</a>
                            </div>
                            <div className='mb-4'>
                                <h1 className='section-title'>{data.sectionThree.title}</h1>
                                {data.sectionThree.links.map((link, linkIdx) =>
                                    <p className='mb-0' key={linkIdx}>
                                        <a href={link.url} target={link.target} rel='noreferrer'>{link.label}</a>
                                    </p>

                                )}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <Card className='contact-card p-3'>
                            <CardBody>
                                {data.inputForm.map((input, inputIdx) =>
                                    <div key={inputIdx} className="mb-3">
                                        <b className='mb-1 text-light'>{input.label}</b>
                                        {input.type !== 'textarea' && <input type={input.type} name={input.name} onChange={(e) => { this.handleChange(input.name, e.target.value) }} value={formProperties[input.name]} className="input-field" />}
                                        {input.type === 'textarea' && <textarea rows={8} name={input.name} onChange={(e) => { this.handleChange(input.name, e.target.value) }} className="input-field-textarea" value={formProperties[input.name]}></textarea>}
                                    </div>
                                )}
                                <button onClick={this.onSubmit.bind(this)} className="send-message-button">Send Message</button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </section>
        </section>
    }
}

export default Contact