import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { PRICING_DATA } from './Data';
import './Pricing.scss';

export class Pricing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: PRICING_DATA,
        }

    }

    handleContact() {
        let linkElement = document.createElement("a");
        linkElement.href = "#contact";
        linkElement.target = "_self";
        linkElement.click();
    }

    render() {
        return <section id="pricing" className={`section bg pricing-container`}>
            <h1 className='section-title text-center py-3'>{PRICING_DATA.title}</h1>

            <Card className='pricing-card' onClick={this.handleContact.bind(this)}>
                <CardBody>
                    <h1 className='pricing-card-title my-5 text-center'>{PRICING_DATA.card.title}</h1>
                    <ul>
                        {PRICING_DATA.card.plans.map((plan, planIdx) =>
                            <li key={planIdx}>
                                {plan}
                            </li>
                        )}
                    </ul>
                    <div className='text-center py-4'>
                        <button className='contact-us-btn'>{PRICING_DATA.card.action.title}</button>
                    </div>
                </CardBody>
            </Card>
        </section>
    }
}

export default Pricing