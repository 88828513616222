import React from 'react';
import { InlineWidget } from "react-calendly";

import './Calendar.scss';

const ENV_CONFIG = window._env_ || {};

export default class Calendar extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        return (
            <div className='section calendly-container'>
                <InlineWidget
                    styles={{ height: '700px' }}
                    url={ENV_CONFIG.HOST_CALENDAR_DEMO}
                />
            </div>
        )
    }
}