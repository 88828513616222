module.exports.FOOTER_DATA = {
    links: [{
        "name": "Quick links",
        "list": [
            { to: "/", label: "Home" },
            { to: "#", label: "Features" },
            { to: "#pricing", label: "Pricing" },
            { to: "#contact", label: "Contact" }
        ]
    },
    {
        "name": "Services",
        "list": [
            { to: '#faq', label: "FAQ" },
            { to: '/privacy', label: "Privacy Policy" },
            { to: '#', label: "Support" },
            { to: '/terms', label: "Terms" }
        ]
    }]
}
