module.exports.DATA = {
    title: `How we benefit you?`,
    colorTitle: ``,
    cards: {
        "BUSINESS": {
            title: `Business`,
            points: [
                'Cost-effective video creation',
                'Wider reach and higher engagement',
                'Unified marketing platform',
                'Enhanced analytics and insights'
            ],
            image: require('../../assets/images/business.png')
        },
        "CREATORS": {
            title: `Creators`,
            points: [
                'Earn rewards for supporting brands you love',
                'Join the creator economy and monetize from day one',
                'Get recognized for your creativity and passion. Grow your personal brand!',
                'Use creator tokens to purchase additional tangible rewards'
            ],
            image: require('../../assets/images/content-creator.png')
        },
        "CONSUMERS": {
            title: `Consumers`,
            points: [
                'Authentic brand content',
                'Community engagement',
                'Discovering local favorites',
                'Deals & discounts'
            ],
            image: require('../../assets/images/consumers.png')
        }
    }
}